import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getOrderById, changeOrderStatus, SaveTrackingInfo, getShippingSlipIntoPdf, getOrderDetailsPdfView, GetOrderDetailsPdfView, getStaffMembers, postOrderResponsibility, getOrderResponsibility } from "../../services/orderService";
import Star from "../User/MyCart/Rating";
import Loader from "../Common/Loader";
import "../../assets/img/favicon.ico";
import { useSelector } from "react-redux";
import TrackingInfoModal from "../Modal/TrackingInfoModal";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertBase64ToPdf } from "../../schema/FileBase64";
// import { Toast } from "react-toastify/dist/components";


function OrderDetails() {
    const [customerDetail, setCustomerDetails] = useState({});
    const [orderProductDetail, setOrderProductDetail] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [currentStatus, setCurrentStatus] = useState();
    const [dropDownStatus, setDropDownStatus] = useState();
    const [orderHistory, setOrdrHistory] = useState();
    const [itemStatus, setItemStatus] = useState(0);
    const { id } = useParams();
    const isDaksh = useSelector((state) => state.auth.isDaksh);
    const [orderNum, setOrderNum] = useState();
    const [showTrackingModal, setShowTrackingModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [staffDropDownStatus, setStaffDropDownStatus] = useState([]);
    const [staffItemStatus, setStaffItemStatus] = useState();


    const [staffId, setStaffId] = useState(null);
    let currentStaffAssigned = '';


    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                const orderDetails = await getOrderById(id);
                const orderResponsibility = await getOrderResponsibility(id);
                currentStaffAssigned= staffItemStatus;
                setStaffItemStatus(orderResponsibility);
            } catch (error) {
                console.error("Error fetching order data:", error);
            }
        };

        fetchOrderData();
    }, [id]);

    useEffect(() => {
        console.log("Staff ID:", staffId);
    }, [staffId]);




    const fetchStaffData = async () => {
        const response = await getStaffMembers();
        setStaffDropDownStatus(response?.result);
    }

    useEffect(() => {
        fetchStaffData();
    }, []);


    const handleStaffItemStatus = (e) => {
        setStaffItemStatus(e.target.value);
        makePostRequest(e.target.value);
    };

    const makePostRequest = async (item) => {
        if (item !== null && item !== 0 ) {
            const postBody = {
                orderId: id,
                memberId: item,
            };
           
            try {
                
                const response = await postOrderResponsibility(postBody);
                console.log('Post API result:', response);
                if(response.statusCode == '204'){
                    toast.success('Staff Assigned Successfully')
                }
            } catch (error) {
                console.error('Error making POST request:', error);
            }
        }
    };


    // useEffect(() => {
    // }, [staffItemStatus, id]);



    const viewClickHandle = async () => {
        const path = "/" + await getOrderDetailsPdfView();
        window.open(path, "_blank", "noopener");
    }

    const getOrderDetailsPdfView = async () => {
        try {
            const response = await GetOrderDetailsPdfView(id);
            return response?.result;
        }
        catch (error) {
            console.log("Something went wrong");
        }
    }

    useEffect(() => {
        getOrderDetailsById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        priceCalculate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderProductDetail]);

    const getOrderDetailsById = async () => {
        setIsLoader(true);
        try {
            const response = await getOrderById(id);
            setCustomerDetails(response?.orderCustomerDetailsResponse);
            setOrderProductDetail(response?.orderProductDetailsResponse);
            setPaymentInfo(response?.orderPaymentInfomationResponse);
            setCurrentStatus(response?.currentStatus);
            setDropDownStatus(response?.dropdownResponse);
            setOrdrHistory(response?.orderHistoryResponse);
            setOrderNum(response?.orderNumber);
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
        setIsLoader(false);
    };

    const priceCalculate = () => {
        let total = 0;
        for (let i = 0; i < orderProductDetail?.length; i++) {
            total = total + orderProductDetail[i]?.price * orderProductDetail[i]?.quantity;
            setTotalPrice(total);
        }
    };

    const getStatusColor = (statusType) => {
        if (statusType === "success") {
            return "text-success";
        } else if (statusType === "failed") {
            return "text-danger";
        } else {
            return "text-primary";
        }
    };
    const toTitleCase = (str) =>
        str
            ?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            ?.map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
            ?.join(" ");

    const handleItemStatus = async (e) => {
        setSelectedValue(e.target.value);
        if (parseInt(e.target.value) === 2) {
            handleTrackingInfoModal();
        } else {
            statusChangeSweetalert(e.target.value);
        }
    };

    const changeStatusApiCall = async (selectedValue) => {
        try {
            setItemStatus(selectedValue);
            const response = await changeOrderStatus(id, selectedValue);
            if (response?.statusCode === 200 || response?.statusCode === 204) {
                getOrderDetailsById();
                toast.success("Status changed successfully")
            }
        } catch (error) {
            console.warn("Something went wrong", error.message);
        }
    };


    const handleDownloadDetails = async () => {
        try {
            const response2 = await getShippingSlipIntoPdf(id);
            const response = {
                result: {
                    fileContents: response2,
                    fileDownloadName: "OrderDetails" + id
                }
            }
            const pdfFile = convertBase64ToPdf(response.result.fileContents, response.result.fileDownloadName);
            const url = URL.createObjectURL(pdfFile);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", pdfFile.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) { console.error("Error downloading PDF:", error) }
    };


    const statusChangeSweetalert = (selectedValue) => {
        Swal.fire({
            text: "Are you sure you want to change the status?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-active-light",
            },
        }).then(async function (result) {
            if (result?.value === true) {
                try {
                    setItemStatus(selectedValue);
                    changeStatusApiCall(selectedValue);
                } catch (error) {
                    console.warn("Something went wrong", error.message);
                }
            }
        });
    };

    const saveTrackingInfo = async (trackingInfo) => {
        setIsLoader(true);
        try {
            const response = await SaveTrackingInfo(id, trackingInfo);
            if (response?.statusCode === 200 || response?.statusCode === 204) {
                console.log("isTrackingInfoSaved");
                toast.success(response?.message);
                changeStatusApiCall(selectedValue);
            }
            if (response?.statusCode === 400) {
                toast.error(response?.message);
            }
            if (response?.statusCode === 500) {
                toast.error(response?.message);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoader(false);
    };
    const handleTrackingInfoModal = () => setShowTrackingModal(!showTrackingModal);

    return (
        <Fragment>
            {isLoader && <Loader />}
            <ToastContainer />
            <TrackingInfoModal
                show={showTrackingModal}
                handleClose={handleTrackingInfoModal}
                handleContinue={saveTrackingInfo}
            />
            <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
                <div className="container-fluid p-0">
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="row-title px-2">
                                <div>
                                    <h6 className="fw-bold">
                                        <Link to={isDaksh ? "/admin/order-management" : "/seller-admin/order-management"}>
                                            {" "}
                                            <i className="fa fa-arrow-left me-2" /> Order
                                        </Link>
                                        <span> </span>#{orderNum}
                                    </h6>
                                </div>
                                <div className="d-flex">
                                    <div className="d-flex  mx-3 py-1">
                                        <span className="mx-1">Assign</span>
                                        <span>To:</span>
                                    </div>
                                
                                    <select
                                        className="form-select form-select-sm rounded"
                                        value={staffItemStatus}
                                        onChange={(e) => handleStaffItemStatus(e)}
                                        disabled={!isDaksh}
                                    >
                                        <option value="0">Change Status</option>
                                        {staffDropDownStatus.map((data) => (
                                            <option key={data?.key} value={data?.key}>
                                                {data?.value}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div class="d-flex morphing scale-left">
                                    <span class="pt-1 mt-1">
                                        Status: <span class="text-teritary">{currentStatus}</span>
                                    </span>
                                    {isDaksh && (
                                        <div class="pe-lg-0 ms-4 border-left ps-3">
                                            <div class="d-sm-block ms-auto">
                                                <div class="input-group">
                                                    <select
                                                        class="form-select form-select-sm rounded"
                                                        value={itemStatus}
                                                        onChange={(e) => handleItemStatus(e)}
                                                        disabled={!isDaksh}>
                                                        <option selected="0">Change Status</option>
                                                        {dropDownStatus?.map((data) => (
                                                            <option
                                                                key={data?.key}
                                                                value={data?.key}>
                                                                {data?.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Product Management header */}
                        <div className="row cart-row pt-3">
                            <div className="col-lg-8">
                                <div className="card border-0">
                                    <div className="card-header pb-0">
                                        <p className="heading">Items</p>
                                    </div>
                                    <div className="card-body product-list scrollbar style-1 mt-0 pt-0">
                                        <div className="row">
                                            {orderProductDetail?.map((data) => (
                                                <div className="col-lg-6">
                                                    <div className="card card-product  mb-3">
                                                        <div className="row p-3">
                                                            <div className="col-lg-4 col-md-3 col-sm-3 col-4 mt-0">
                                                                {data?.imageURL != null ? (
                                                                    <img
                                                                        src={data?.imageURL}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img alt="" />
                                                                )}
                                                            </div>
                                                            <div className="col-lg-8 col-md-9 col-sm-9 col-8">
                                                                <div className="details">
                                                                    <p className="mb-2 text-muted h6">{data?.productCode}</p>
                                                                    <p
                                                                        className="mb-1"
                                                                        style={{
                                                                            fontSize: "1rem",
                                                                            fontweight: "bold",
                                                                        }}>
                                                                        {data?.productName}
                                                                    </p> {data?.productName?.length < 19 ? <br /> : ""}
                                                                    <p className="mb-1">{data?.material}</p>
                                                                    <p
                                                                        className="mb-1"
                                                                        style={{ marginTop: "20px" }}>
                                                                        {/* {data.ratings} */}
                                                                        <Star stars={data?.productRating} />
                                                                    </p>
                                                                    <h6 className="mb-2">
                                                                        <strong>
                                                                            <span>₹ {data?.price}</span>
                                                                        </strong>
                                                                    </h6>
                                                                    {/* <h6 className="text-success cart-price">150 * 5 = <span>750</span></h6> */}
                                                                </div>
                                                                <div className="quantity-button">
                                                                    <div className="input-group mb-3 ">
                                                                        <input
                                                                            type="number"
                                                                            min={0}
                                                                            value={data.quantity}
                                                                            style={{ height: "33px", width: "49px" }}
                                                                            disabled={true}
                                                                        />
                                                                        <span
                                                                            className="input-group-text"
                                                                            id="basic-addon2">
                                                                            <i className="fa-solid fa-cart-plus" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 row">
                                {isDaksh && <div className="card card-form mb-2 " style={{ "height": "45px" }}>
                                    <div className="d-flex" style={{ "justify-content": "center" }}>
                                        <div className="mb-2">
                                            <button className="btn" onClick={(e) => viewClickHandle(e)}><i class="fa-regular fa-eye" ></i> View</button>
                                            {/* <button className="btn" ><i class="fa-regular fa-eye" ></i> View</button> */}
                                        </div>
                                        <div>
                                            <button onClick={handleDownloadDetails} className="btn"><i class="fa-solid fa-download"></i> Download slip</button>
                                        </div>
                                    </div>
                                </div>}
                                <div className="card form-card">
                                    <div className="card-body">
                                        <div class="col transection-details">
                                            <div class="mb-3">
                                                <div class="card-header">
                                                    <h6 class="card-title m-0">Order History</h6>
                                                    {/* <span href="" class="minimize">
                            <i class="fa fa-minus text-primary"></i>
                          </span> */}
                                                </div>

                                                <div className="card-body pt-0">
                                                    <ul className="list-unstyled submit-track ps-3 mt-2">
                                                        {orderHistory?.map(
                                                            (status, index) =>
                                                                // Check if the status is cancelled and statusChangeDate is not empty
                                                                !(status.orderStatus === "Cancelled" && !status.statusChangeDate) && (
                                                                    <li
                                                                        key={index}
                                                                        className={`${status.orderStatus === "Cancelled" && !status.statusChangeDate ? "text-black-50" : "is-complete"}`}>
                                                                        <div className={`circle ${status.statusChangeDate ? (status.orderStatus === "Cancelled" ? "bg-danger" : "bg-success") : "bg-light"}`}>
                                                                            <i className="fa fa-check"></i>
                                                                        </div>
                                                                        {status.statusChangeDate ? <small>{status.statusChangeDate}</small> : <small></small>}
                                                                        <div className="box-title d-flex justify-content-between align-items-center">{status.orderStatus.charAt(0).toUpperCase() + status.orderStatus.slice(1)}</div>
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card form-card">
                                    <div className="card-body">
                                        <p className="heading">Customer Detail</p>
                                        <form
                                            action="#"
                                            className="row">
                                            <div className="mb-3">
                                                <label className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={customerDetail?.name}
                                                    disabled="disabled"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Mobile</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    value={customerDetail?.mobileNumber}
                                                    disabled="disabled"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id=""
                                                    value={customerDetail?.email}
                                                    disabled="disabled"
                                                />
                                            </div>

                                            {/* <div className="mb-3">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          value={customerDetail?.state}
                          disabled="disabled"
                        />
                      </div> */}
                                            <div className="mb-3">
                                                <label className="form-label">Address</label>
                                                <textarea
                                                    name=""
                                                    id=""
                                                    className="form-control"
                                                    rows={2}
                                                    disabled="disabled"
                                                    value={customerDetail?.address}
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-6">
                                                <label className="form-label">State</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    value={customerDetail?.state}
                                                    disabled="disabled"
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-6">
                                                <label className="form-label">Pin Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    value={customerDetail?.pincode}
                                                    disabled="disabled"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card form-card">
                                    <div className="mb-3">
                                        <div className="card-body">
                                            <h6 className="card-title mb-3">Payment Info</h6>
                                            <ul className="list-unstyled mb-0">
                                                <li className="py-2">
                                                    <span className="text-muted me-2 w125 d-inline-block">Transaction Id:</span>
                                                    {paymentInfo?.transactionId}
                                                </li>
                                                <li className="py-2">
                                                    <span className="text-muted me-2 w125 d-inline-block">Sender URL:</span> {paymentInfo?.sellerURL}
                                                </li>
                                                <li className="py-2">
                                                    <span className="text-muted me-2 w125 d-inline-block">Date & Time:</span>
                                                    {paymentInfo?.tranactionDate}
                                                </li>
                                                <li className="py-2">
                                                    <span className="text-muted me-2 w125 d-inline-block">Mode:</span>
                                                    {paymentInfo?.modeType}
                                                </li>

                                                <li className="py-2">
                                                    <span className="text-muted me-2 w125 d-inline-block">Status:</span>
                                                    <span className={getStatusColor(paymentInfo?.statusType)}>{paymentInfo?.statusType}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card bg-success mt-3 total-price-card">
                                    <div className="card-body">
                                        <p className="mb-0">
                                            <span className="items">{orderProductDetail?.length > 0 ? orderProductDetail?.length : 0} Items</span>{" "}
                                            <span className="price ps-1">
                                                {" "}
                                                <strong>₹{totalPrice}</strong>
                                            </span>{" "}
                                        </p>
                                        <p className="request mb-0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default OrderDetails;
